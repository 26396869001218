.h1b {
  font-size: 36px;
  font-weight: 700;
}

.h1s {
  font-size: 36px;
  font-weight: 600;
}

.h1m {
  font-size: 36px;
  font-weight: 500;
}

.h1bc {
  font-size: 24px;
  font-weight: 700;
}

.h1sc {
  font-size: 24px;
  font-weight: 600;
}

.h1mc {
  font-size: 24px;
  font-weight: 500;
}

.h2b {
  font-size: 30px;
  font-weight: 700;
}

.h2s {
  font-size: 30px;
  font-weight: 600;
}

.h2m {
  font-size: 30px;
  font-weight: 500;
}

.h2bc {
  font-size: 18px;
  font-weight: 700;
}

.h2sc {
  font-size: 18px;
  font-weight: 600;
}

.h2mc {
  font-size: 18px;
  font-weight: 500;
}

.h3b {
  font-size: 24px;
  font-weight: 700;
}

.h3s {
  font-size: 24px;
  font-weight: 600;
}

.h3m {
  font-size: 24px;
  font-weight: 500;
}

.h3bc {
  font-size: 16px;
  font-weight: 700;
}

.h3sc {
  font-size: 16px;
  font-weight: 600;
}

.h3mc {
  font-size: 16px;
  font-weight: 500;
}

.h4b {
  font-size: 18px;
  font-weight: 700;
}

.h4s {
  font-size: 18px;
  font-weight: 600;
}

.h4m {
  font-size: 18px;
  font-weight: 500;
}

.h4bc {
  font-size: 14px;
  font-weight: 700;
}

.h4sc {
  font-size: 14px;
  font-weight: 600;
}

.h4mc {
  font-size: 14px;
  font-weight: 500;
}

.h5b {
  font-size: 16px;
  font-weight: 700;
}

.h5sm {
  font-size: 16px;
  font-weight: 600;
}

.h5m {
  font-size: 16px;
  font-weight: 500;
}

.h5b {
  font-size: 12px;
  font-weight: 700;
}

.h5bc {
  font-size: 12px;
  font-weight: 600;
}

.h5mc {
  font-size: 12px;
  font-weight: 500;
}

.sh1b {
  font-size: 18px;
  font-weight: 700;
}

.sh1s {
  font-size: 18px;
  font-weight: 500;
}

.sh1u {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.p1b {
  font-size: 16px;
  font-weight: 600;
}

.p1s {
  font-size: 16px;
  font-weight: 400;
}

.p1u {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.p2b {
  font-size: 14px;
  font-weight: 600;
}

.p2s {
  font-size: 14px;
  font-weight: 400;
}

.p2u {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.p3b {
  font-size: 12px;
  font-weight: 600;
}

.p3s {
  font-size: 12px;
  font-weight: 400;
}

.p3u {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.c3b {
  font-size: 10px;
  font-weight: 600;
}

.c3s {
  font-size: 10px;
  font-weight: 400;
}

.c3u {
  font-size: 10px;
  font-weight: 400;
  text-decoration: underline;
}

* {
  font-family: "Poppins";
}

.title-01 {
  font-family: "Poppins";
  color: #333333;
  font-size: 17px;
  font-weight: 600;
}

.title-3 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 700;
}

.title-2 {
  font-family: "Poppins";
  color: #333333;
  font-size: 21px;
  font-weight: 600;
}

.title-1 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 27px;
  font-weight: 700;
}

.parr-4 {
  font-size: 15px;
}

.parr-3 {
  color: rgba(53, 52, 52, 0.5);
}

.parr-2 {
  font-size: 14px;
  color: #414350;
  font-weight: bold;
}

.parr-1 {
  font-size: 11px;
  color: #388aed;
  font-weight: bold;
}

@font-face {
  font-family: "SFcompact";
  src: url("../../../assets/scss/fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: "SFcompactLight";
  src: url("../../../assets/scss/fonts/sf-compact-display-thin.otf");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Light.otf");
}
@font-face {
  font-family: "PoppinsThin";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Thin.otf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: "WorkSansLight";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: "WorkSansSemiBold";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-SemiBold.ttf");
}
.poppinsR {
  font-family: "PoppinsRegular";
}

.poppinsT {
  font-family: "PoppinsThin";
}

.poppinsL {
  font-family: "PoppinsLight";
}

.poppinsB {
  font-family: "PoppinsBold";
}

.h1b {
  font-size: 36px;
  font-weight: 700;
}

.h1s {
  font-size: 36px;
  font-weight: 600;
}

.h1m {
  font-size: 36px;
  font-weight: 500;
}

.h1bc {
  font-size: 24px;
  font-weight: 700;
}

.h1sc {
  font-size: 24px;
  font-weight: 600;
}

.h1mc {
  font-size: 24px;
  font-weight: 500;
}

.h2b {
  font-size: 30px;
  font-weight: 700;
}

.h2s {
  font-size: 30px;
  font-weight: 600;
}

.h2m {
  font-size: 30px;
  font-weight: 500;
}

.h2bc {
  font-size: 18px;
  font-weight: 700;
}

.h2sc {
  font-size: 18px;
  font-weight: 600;
}

.h2mc {
  font-size: 18px;
  font-weight: 500;
}

.h3b {
  font-size: 24px;
  font-weight: 700;
}

.h3s {
  font-size: 24px;
  font-weight: 600;
}

.h3m {
  font-size: 24px;
  font-weight: 500;
}

.h3bc {
  font-size: 16px;
  font-weight: 700;
}

.h3sc {
  font-size: 16px;
  font-weight: 600;
}

.h3mc {
  font-size: 16px;
  font-weight: 500;
}

.h4b {
  font-size: 18px;
  font-weight: 700;
}

.h4s {
  font-size: 18px;
  font-weight: 600;
}

.h4m {
  font-size: 18px;
  font-weight: 500;
}

.h4bc {
  font-size: 14px;
  font-weight: 700;
}

.h4sc {
  font-size: 14px;
  font-weight: 600;
}

.h4mc {
  font-size: 14px;
  font-weight: 500;
}

.h5b {
  font-size: 16px;
  font-weight: 700;
}

.h5sm {
  font-size: 16px;
  font-weight: 600;
}

.h5m {
  font-size: 16px;
  font-weight: 500;
}

.h5b {
  font-size: 12px;
  font-weight: 700;
}

.h5bc {
  font-size: 12px;
  font-weight: 600;
}

.h5mc {
  font-size: 12px;
  font-weight: 500;
}

.sh1b {
  font-size: 18px;
  font-weight: 700;
}

.sh1s {
  font-size: 18px;
  font-weight: 500;
}

.sh1u {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.p1b {
  font-size: 16px;
  font-weight: 600;
}

.p1s {
  font-size: 16px;
  font-weight: 400;
}

.p1u {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.p2b {
  font-size: 14px;
  font-weight: 600;
}

.p2s {
  font-size: 14px;
  font-weight: 400;
}

.p2u {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.p3b {
  font-size: 12px;
  font-weight: 600;
}

.p3s {
  font-size: 12px;
  font-weight: 400;
}

.p3u {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.c3b {
  font-size: 10px;
  font-weight: 600;
}

.c3s {
  font-size: 10px;
  font-weight: 400;
}

.c3u {
  font-size: 10px;
  font-weight: 400;
  text-decoration: underline;
}

* {
  font-family: "Poppins";
}

.title-01 {
  font-family: "Poppins";
  color: #333333;
  font-size: 17px;
  font-weight: 600;
}

.title-3 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 700;
}

.title-2 {
  font-family: "Poppins";
  color: #333333;
  font-size: 21px;
  font-weight: 600;
}

.title-1 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 27px;
  font-weight: 700;
}

.parr-4 {
  font-size: 15px;
}

.parr-3 {
  color: rgba(53, 52, 52, 0.5);
}

.parr-2 {
  font-size: 14px;
  color: #414350;
  font-weight: bold;
}

.parr-1 {
  font-size: 11px;
  color: #388aed;
  font-weight: bold;
}

.field_text_large {
  text-align: left;
  --mdc-outlined-text-field-container-shape:8px;
  --mat-optgroup-label-text-font:$PPINSB;
  --mdc-outlined-text-field-label-text-font:$PPINSB;
  --mdc-outlined-text-field-outline-color: #565459;
  --mat-form-field-container-height:44px;
  --mdc-outlined-text-field-label-text-font:$PPINSB;
}
.field_text_large .mat-mdc-form-field-flex {
  height: 44px;
}
.field_text_large .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
.field_text_large .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-family: "PoppinsRegular";
  font-size: 15px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.0015em;
}
.field_text_large .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #565459 !important;
}
.field_text_large .mdc-text-field--outlined .mat-mdc-form-field-infix, .field_text_large .mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding: 1px;
  display: flex;
  align-items: center;
}

/*******************************/
/*******************************/
@font-face {
  font-family: "SFcompact";
  src: url("./fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: "SFcompactLight";
  src: url("./fonts/sf-compact-display-thin.otf");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("./fonts/poppins/Poppins-Light.otf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("./fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("./fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: "MulishLight";
  src: url("./fonts/Mulish/Mulish-Light.ttf");
}
@font-face {
  font-family: "MulishRegular";
  src: url("./fonts/Mulish/Mulish-Regular.ttf");
}
@font-face {
  font-family: "MulishBold";
  src: url("./fonts/Mulish/Mulish-Bold.ttf");
}
@font-face {
  font-family: "WorkSansLight";
  src: url("./fonts/Work_Sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: "WorkSansSemiBold";
  src: url("./fonts/Work_Sans/WorkSans-SemiBold.ttf");
}
/*Theme Colors*/
/*Topbar Colors*/
/*Sidebar Colors*/
/*Boxed layout width*/
/*Shadow*/
/*transitions*/
/*Dark transparent bg*/
/*******************************/
/*******************************/
/**
* Table Of Content
*
*  1. Color system
*  2. Options
*  3. Body
*  4. Typography
*  5. Breadcrumbs
*  6. Cards
*  7. Dropdowns
*  8. Buttons
*  9. Typography
*  10. Progress bars
*  11. Tables
*  12. Forms
*  14. Component
*/
.custom__class-input-filters {
  max-width: 190px !important;
  width: 100% !important;
}

::ng-deep .custom__class-input-filters .mat-form-field-infix input {
  font-family: "PoppinsRegular" !important;
  font-size: 13px !important;
  color: #374357 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-label {
  color: #565459 !important;
  top: 18px !important;
  margin-top: 0 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #565459 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field.mat-focused .mat-form-field-label,
.mat-form-field.mat-focused .mat-form-field-label.mat-accent,
.mat-form-field.mat-focused .mat-form-field-label.mat-warn,
.mat-focused .mat-form-field-required-marker {
  color: #565459 !important;
  font-weight: 500;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 5px 4px 8px 0px !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border: none;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border: none;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline,
::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-form-field-should-float .mat-form-field-outline {
  background-color: #E2E8F1 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  background-color: #F6F6F6 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  background-color: #F6F6F6 !important;
}

::ng-deep .custom__class-input-filters.mat-input-element {
  color: #374357 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #565459 !important;
  border: 1px solid #D7D5D8 !important;
  border-radius: 8px !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-flex:hover {
  color: #565459 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
  border: 1px solid #1E293B !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-label {
  color: #565459 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-outline,
.mat-form-field-empty.mat-form-field-label,
.mat-form-field-label,
.mat-form-field-underline,
.mat-input-element,
::placeholder {
  color: #565459 !important;
  font-size: 14px !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff9196 !important;
}

::ng-deep .custom__class-input-filters.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border: none;
  border-radius: 0;
}

::ng-deep .custom__class-input-filters.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  display: none !important;
}

.h1b {
  font-size: 36px;
  font-weight: 700;
}

.h1s {
  font-size: 36px;
  font-weight: 600;
}

.h1m {
  font-size: 36px;
  font-weight: 500;
}

.h1bc {
  font-size: 24px;
  font-weight: 700;
}

.h1sc {
  font-size: 24px;
  font-weight: 600;
}

.h1mc {
  font-size: 24px;
  font-weight: 500;
}

.h2b {
  font-size: 30px;
  font-weight: 700;
}

.h2s {
  font-size: 30px;
  font-weight: 600;
}

.h2m {
  font-size: 30px;
  font-weight: 500;
}

.h2bc {
  font-size: 18px;
  font-weight: 700;
}

.h2sc {
  font-size: 18px;
  font-weight: 600;
}

.h2mc {
  font-size: 18px;
  font-weight: 500;
}

.h3b {
  font-size: 24px;
  font-weight: 700;
}

.h3s {
  font-size: 24px;
  font-weight: 600;
}

.h3m {
  font-size: 24px;
  font-weight: 500;
}

.h3bc {
  font-size: 16px;
  font-weight: 700;
}

.h3sc {
  font-size: 16px;
  font-weight: 600;
}

.h3mc {
  font-size: 16px;
  font-weight: 500;
}

.h4b {
  font-size: 18px;
  font-weight: 700;
}

.h4s {
  font-size: 18px;
  font-weight: 600;
}

.h4m {
  font-size: 18px;
  font-weight: 500;
}

.h4bc {
  font-size: 14px;
  font-weight: 700;
}

.h4sc {
  font-size: 14px;
  font-weight: 600;
}

.h4mc {
  font-size: 14px;
  font-weight: 500;
}

.h5b {
  font-size: 16px;
  font-weight: 700;
}

.h5sm {
  font-size: 16px;
  font-weight: 600;
}

.h5m {
  font-size: 16px;
  font-weight: 500;
}

.h5b {
  font-size: 12px;
  font-weight: 700;
}

.h5bc {
  font-size: 12px;
  font-weight: 600;
}

.h5mc {
  font-size: 12px;
  font-weight: 500;
}

.sh1b {
  font-size: 18px;
  font-weight: 700;
}

.sh1s {
  font-size: 18px;
  font-weight: 500;
}

.sh1u {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.p1b {
  font-size: 16px;
  font-weight: 600;
}

.p1s {
  font-size: 16px;
  font-weight: 400;
}

.p1u {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.p2b {
  font-size: 14px;
  font-weight: 600;
}

.p2s {
  font-size: 14px;
  font-weight: 400;
}

.p2u {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.p3b {
  font-size: 12px;
  font-weight: 600;
}

.p3s {
  font-size: 12px;
  font-weight: 400;
}

.p3u {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.c3b {
  font-size: 10px;
  font-weight: 600;
}

.c3s {
  font-size: 10px;
  font-weight: 400;
}

.c3u {
  font-size: 10px;
  font-weight: 400;
  text-decoration: underline;
}

* {
  font-family: "Poppins";
}

.title-01 {
  font-family: "Poppins";
  color: #333333;
  font-size: 17px;
  font-weight: 600;
}

.title-3 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 700;
}

.title-2 {
  font-family: "Poppins";
  color: #333333;
  font-size: 21px;
  font-weight: 600;
}

.title-1 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 27px;
  font-weight: 700;
}

.parr-4 {
  font-size: 15px;
}

.parr-3 {
  color: rgba(53, 52, 52, 0.5);
}

.parr-2 {
  font-size: 14px;
  color: #414350;
  font-weight: bold;
}

.parr-1 {
  font-size: 11px;
  color: #388aed;
  font-weight: bold;
}

.label-1 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 15px;
  font-weight: 700;
}

.label-blue-1 {
  font-family: "Poppins";
  color: #388aed;
  font-size: 15px;
  font-weight: 700;
}

.header-text-with-prefix-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  padding-left: 12px;
  padding-right: 16px;
}

@font-face {
  font-family: "SFcompact";
  src: url("../../../assets/scss/fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: "SFcompactLight";
  src: url("../../../assets/scss/fonts/sf-compact-display-thin.otf");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Light.otf");
}
@font-face {
  font-family: "PoppinsThin";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Thin.otf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: "WorkSansLight";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: "WorkSansSemiBold";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-SemiBold.ttf");
}
.poppinsR {
  font-family: "PoppinsRegular";
}

.poppinsT {
  font-family: "PoppinsThin";
}

.poppinsL {
  font-family: "PoppinsLight";
}

.poppinsB {
  font-family: "PoppinsBold";
}

.h1_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h1_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h1_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h1_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h1_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h2_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h2_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h2_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h2_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h2_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h3_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 2.8rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h3_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h3_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h3_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h3_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h4_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h4_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h4_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h4_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h4_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h5_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h5_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h5_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h5_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h5_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h6_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_300.secondary {
  color: #374357 !important;
}

.h6_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_400.secondary {
  color: #374357 !important;
}

.h6_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_500.secondary {
  color: #374357 !important;
}

.h6_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_600.secondary {
  color: #374357 !important;
}

.h6_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_700.secondary {
  color: #374357 !important;
}

p.subtitle-1, a.subtitle-1, span.subtitle-1 {
  font-family: "PoppinsRegular";
  font-size: 15px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.0015em;
  color: #6B778C;
}

.subtitle-2 {
  font-family: "PoppinsLight";
  font-size: 13px;
  font-weight: 500;
  line-height: 16.9px;
  letter-spacing: 0.001em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6B778C;
}

.body-1 {
  font-family: "PoppinsRegular";
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6B778C;
}

.body-2, .body-2-Pt {
  font-family: "PoppinsRegular";
  font-size: 13px;
  font-weight: 400;
  line-height: 16.9px;
  letter-spacing: 0.0025em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}

.body-2-Pt {
  font-family: "PoppinsThin";
}

.txt-c {
  text-align: center;
}

.h1b {
  font-size: 36px;
  font-weight: 700;
}

.h1s {
  font-size: 36px;
  font-weight: 600;
}

.h1m {
  font-size: 36px;
  font-weight: 500;
}

.h1bc {
  font-size: 24px;
  font-weight: 700;
}

.h1sc {
  font-size: 24px;
  font-weight: 600;
}

.h1mc {
  font-size: 24px;
  font-weight: 500;
}

.h2b {
  font-size: 30px;
  font-weight: 700;
}

.h2s {
  font-size: 30px;
  font-weight: 600;
}

.h2m {
  font-size: 30px;
  font-weight: 500;
}

.h2bc {
  font-size: 18px;
  font-weight: 700;
}

.h2sc {
  font-size: 18px;
  font-weight: 600;
}

.h2mc {
  font-size: 18px;
  font-weight: 500;
}

.h3b {
  font-size: 24px;
  font-weight: 700;
}

.h3s {
  font-size: 24px;
  font-weight: 600;
}

.h3m {
  font-size: 24px;
  font-weight: 500;
}

.h3bc {
  font-size: 16px;
  font-weight: 700;
}

.h3sc {
  font-size: 16px;
  font-weight: 600;
}

.h3mc {
  font-size: 16px;
  font-weight: 500;
}

.h4b {
  font-size: 18px;
  font-weight: 700;
}

.h4s {
  font-size: 18px;
  font-weight: 600;
}

.h4m {
  font-size: 18px;
  font-weight: 500;
}

.h4bc {
  font-size: 14px;
  font-weight: 700;
}

.h4sc {
  font-size: 14px;
  font-weight: 600;
}

.h4mc {
  font-size: 14px;
  font-weight: 500;
}

.h5b {
  font-size: 16px;
  font-weight: 700;
}

.h5sm {
  font-size: 16px;
  font-weight: 600;
}

.h5m {
  font-size: 16px;
  font-weight: 500;
}

.h5b {
  font-size: 12px;
  font-weight: 700;
}

.h5bc {
  font-size: 12px;
  font-weight: 600;
}

.h5mc {
  font-size: 12px;
  font-weight: 500;
}

.sh1b {
  font-size: 18px;
  font-weight: 700;
}

.sh1s {
  font-size: 18px;
  font-weight: 500;
}

.sh1u {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.p1b {
  font-size: 16px;
  font-weight: 600;
}

.p1s {
  font-size: 16px;
  font-weight: 400;
}

.p1u {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.p2b {
  font-size: 14px;
  font-weight: 600;
}

.p2s {
  font-size: 14px;
  font-weight: 400;
}

.p2u {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.p3b {
  font-size: 12px;
  font-weight: 600;
}

.p3s {
  font-size: 12px;
  font-weight: 400;
}

.p3u {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.c3b {
  font-size: 10px;
  font-weight: 600;
}

.c3s {
  font-size: 10px;
  font-weight: 400;
}

.c3u {
  font-size: 10px;
  font-weight: 400;
  text-decoration: underline;
}

* {
  font-family: "Poppins";
}

.title-01 {
  font-family: "Poppins";
  color: #333333;
  font-size: 17px;
  font-weight: 600;
}

.title-3 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 700;
}

.title-2 {
  font-family: "Poppins";
  color: #333333;
  font-size: 21px;
  font-weight: 600;
}

.title-1 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 27px;
  font-weight: 700;
}

.parr-4 {
  font-size: 15px;
}

.parr-3 {
  color: rgba(53, 52, 52, 0.5);
}

.parr-2 {
  font-size: 14px;
  color: #414350;
  font-weight: bold;
}

.parr-1 {
  font-size: 11px;
  color: #388aed;
  font-weight: bold;
}

.mini-card-1 {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 190px;
  height: 100px;
  padding: 15px;
  text-align: center;
}

.mini-card-1.borderless {
  box-shadow: none;
  border: none;
  width: 190px;
  height: 100px;
  padding: 15px;
  text-align: center;
  opacity: 0.5;
}

.h1b {
  font-size: 36px;
  font-weight: 700;
}

.h1s {
  font-size: 36px;
  font-weight: 600;
}

.h1m {
  font-size: 36px;
  font-weight: 500;
}

.h1bc {
  font-size: 24px;
  font-weight: 700;
}

.h1sc {
  font-size: 24px;
  font-weight: 600;
}

.h1mc {
  font-size: 24px;
  font-weight: 500;
}

.h2b {
  font-size: 30px;
  font-weight: 700;
}

.h2s {
  font-size: 30px;
  font-weight: 600;
}

.h2m {
  font-size: 30px;
  font-weight: 500;
}

.h2bc {
  font-size: 18px;
  font-weight: 700;
}

.h2sc {
  font-size: 18px;
  font-weight: 600;
}

.h2mc {
  font-size: 18px;
  font-weight: 500;
}

.h3b {
  font-size: 24px;
  font-weight: 700;
}

.h3s {
  font-size: 24px;
  font-weight: 600;
}

.h3m {
  font-size: 24px;
  font-weight: 500;
}

.h3bc {
  font-size: 16px;
  font-weight: 700;
}

.h3sc {
  font-size: 16px;
  font-weight: 600;
}

.h3mc {
  font-size: 16px;
  font-weight: 500;
}

.h4b {
  font-size: 18px;
  font-weight: 700;
}

.h4s {
  font-size: 18px;
  font-weight: 600;
}

.h4m {
  font-size: 18px;
  font-weight: 500;
}

.h4bc {
  font-size: 14px;
  font-weight: 700;
}

.h4sc {
  font-size: 14px;
  font-weight: 600;
}

.h4mc {
  font-size: 14px;
  font-weight: 500;
}

.h5b {
  font-size: 16px;
  font-weight: 700;
}

.h5sm {
  font-size: 16px;
  font-weight: 600;
}

.h5m {
  font-size: 16px;
  font-weight: 500;
}

.h5b {
  font-size: 12px;
  font-weight: 700;
}

.h5bc {
  font-size: 12px;
  font-weight: 600;
}

.h5mc {
  font-size: 12px;
  font-weight: 500;
}

.sh1b {
  font-size: 18px;
  font-weight: 700;
}

.sh1s {
  font-size: 18px;
  font-weight: 500;
}

.sh1u {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.p1b {
  font-size: 16px;
  font-weight: 600;
}

.p1s {
  font-size: 16px;
  font-weight: 400;
}

.p1u {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.p2b {
  font-size: 14px;
  font-weight: 600;
}

.p2s {
  font-size: 14px;
  font-weight: 400;
}

.p2u {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.p3b {
  font-size: 12px;
  font-weight: 600;
}

.p3s {
  font-size: 12px;
  font-weight: 400;
}

.p3u {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.c3b {
  font-size: 10px;
  font-weight: 600;
}

.c3s {
  font-size: 10px;
  font-weight: 400;
}

.c3u {
  font-size: 10px;
  font-weight: 400;
  text-decoration: underline;
}

* {
  font-family: "Poppins";
}

.title-01 {
  font-family: "Poppins";
  color: #333333;
  font-size: 17px;
  font-weight: 600;
}

.title-3 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 700;
}

.title-2 {
  font-family: "Poppins";
  color: #333333;
  font-size: 21px;
  font-weight: 600;
}

.title-1 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 27px;
  font-weight: 700;
}

.parr-4 {
  font-size: 15px;
}

.parr-3 {
  color: rgba(53, 52, 52, 0.5);
}

.parr-2 {
  font-size: 14px;
  color: #414350;
  font-weight: bold;
}

.parr-1 {
  font-size: 11px;
  color: #388aed;
  font-weight: bold;
}

.button-blue-semirounded-1 {
  font-family: "Poppins";
  color: white;
  font-size: 15px;
  font-weight: bold;
  background: #388AED;
  border: none;
  padding: 7px 20px;
  border-radius: 8px;
}

.button-blue-1 {
  font-family: "Poppins";
  color: white;
  font-size: 15px;
  font-weight: bold;
  background: #388AED;
  border: none;
  padding: 7px 20px;
  border-radius: 22px;
}

.button-blue-outline-1 {
  font-family: "Poppins";
  color: #388AED;
  font-size: 15px;
  font-weight: bold;
  background: white;
  border: 1px solid #03325B;
  padding: 7px 20px;
  border: 1px solid #388AED;
  border-radius: 22px;
}

.button-darkblue-1 {
  font-family: "Poppins";
  color: white;
  font-size: 15px;
  font-weight: bold;
  background: #03325B;
  border: none;
  padding: 7px 20px;
  border-radius: 22px;
}
.button-darkblue-1:disabled {
  background: #03325b7d;
  cursor: no-drop;
}

.button-darkblue-outline-1 {
  font-family: "Poppins";
  color: #03325B;
  font-size: 15px;
  font-weight: bold;
  background: white;
  border: none;
  border: 1px solid #03325B;
  padding: 7px 20px;
  border-radius: 22px;
}

@font-face {
  font-family: "SFcompact";
  src: url("../../../assets/scss/fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: "SFcompactLight";
  src: url("../../../assets/scss/fonts/sf-compact-display-thin.otf");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Light.otf");
}
@font-face {
  font-family: "PoppinsThin";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Thin.otf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: "WorkSansLight";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: "WorkSansSemiBold";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-SemiBold.ttf");
}
.poppinsR {
  font-family: "PoppinsRegular";
}

.poppinsT {
  font-family: "PoppinsThin";
}

.poppinsL {
  font-family: "PoppinsLight";
}

.poppinsB {
  font-family: "PoppinsBold";
}

button.btn_secondary_text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-height: 40px;
  width: auto;
  max-width: 328px;
  padding: 8px 16px 8px 16px;
  border-radius: 360px;
  font-family: "PoppinsLight";
  font-size: 13px;
  font-weight: 500;
  color: #374357;
}
button.btn_secondary_text .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
button.btn_secondary_text:disabled {
  color: #6B778C;
  cursor: no-drop;
}
button.btn_secondary_text:hover .mat-button-focus-overlay {
  background: #369752;
}
button.btn_secondary_text:active .mat-button-focus-overlay, button.btn_secondary_text:focus .mat-button-focus-overlay {
  background: #369752;
}

button.btn_secondary_text__icon_left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-height: 40px;
  width: auto;
  max-width: 328px;
  padding: 8px 16px 8px 16px;
  border-radius: 360px;
  font-family: "PoppinsLight";
  font-size: 13px;
  font-weight: 500;
  color: #374357;
  padding: 8px 16px 8px 12px;
  flex-flow: reverse;
}
button.btn_secondary_text__icon_left .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
button.btn_secondary_text__icon_left:disabled {
  color: #6B778C;
  cursor: no-drop;
}
button.btn_secondary_text__icon_left:hover .mat-button-focus-overlay {
  background: #369752;
}
button.btn_secondary_text__icon_left:active .mat-button-focus-overlay, button.btn_secondary_text__icon_left:focus .mat-button-focus-overlay {
  background: #369752;
}

button.btn_secondary_text__icon_rigth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-height: 40px;
  width: auto;
  max-width: 328px;
  padding: 8px 16px 8px 16px;
  border-radius: 360px;
  font-family: "PoppinsLight";
  font-size: 13px;
  font-weight: 500;
  color: #374357;
  padding: 8px 12px 8px 16px;
}
button.btn_secondary_text__icon_rigth .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
button.btn_secondary_text__icon_rigth:disabled {
  color: #6B778C;
  cursor: no-drop;
}
button.btn_secondary_text__icon_rigth:hover .mat-button-focus-overlay {
  background: #369752;
}
button.btn_secondary_text__icon_rigth:active .mat-button-focus-overlay, button.btn_secondary_text__icon_rigth:focus .mat-button-focus-overlay {
  background: #369752;
}

@media screen and (max-width: 1024px) {
  button.btn_secondary_text__icon_left .mat-button-wrapper :not(:first-child) {
    display: none;
  }

  button.btn_secondary_text__icon_rigth {
    padding: 8px 16px 8px 16px;
  }
  button.btn_secondary_text__icon_rigth .mat-button-wrapper :first-child {
    display: none;
  }
}
.pointer {
  cursor: pointer;
}

.h1b {
  font-size: 36px;
  font-weight: 700;
}

.h1s {
  font-size: 36px;
  font-weight: 600;
}

.h1m {
  font-size: 36px;
  font-weight: 500;
}

.h1bc {
  font-size: 24px;
  font-weight: 700;
}

.h1sc {
  font-size: 24px;
  font-weight: 600;
}

.h1mc {
  font-size: 24px;
  font-weight: 500;
}

.h2b {
  font-size: 30px;
  font-weight: 700;
}

.h2s {
  font-size: 30px;
  font-weight: 600;
}

.h2m {
  font-size: 30px;
  font-weight: 500;
}

.h2bc {
  font-size: 18px;
  font-weight: 700;
}

.h2sc {
  font-size: 18px;
  font-weight: 600;
}

.h2mc {
  font-size: 18px;
  font-weight: 500;
}

.h3b {
  font-size: 24px;
  font-weight: 700;
}

.h3s {
  font-size: 24px;
  font-weight: 600;
}

.h3m {
  font-size: 24px;
  font-weight: 500;
}

.h3bc {
  font-size: 16px;
  font-weight: 700;
}

.h3sc {
  font-size: 16px;
  font-weight: 600;
}

.h3mc {
  font-size: 16px;
  font-weight: 500;
}

.h4b {
  font-size: 18px;
  font-weight: 700;
}

.h4s {
  font-size: 18px;
  font-weight: 600;
}

.h4m {
  font-size: 18px;
  font-weight: 500;
}

.h4bc {
  font-size: 14px;
  font-weight: 700;
}

.h4sc {
  font-size: 14px;
  font-weight: 600;
}

.h4mc {
  font-size: 14px;
  font-weight: 500;
}

.h5b {
  font-size: 16px;
  font-weight: 700;
}

.h5sm {
  font-size: 16px;
  font-weight: 600;
}

.h5m {
  font-size: 16px;
  font-weight: 500;
}

.h5b {
  font-size: 12px;
  font-weight: 700;
}

.h5bc {
  font-size: 12px;
  font-weight: 600;
}

.h5mc {
  font-size: 12px;
  font-weight: 500;
}

.sh1b {
  font-size: 18px;
  font-weight: 700;
}

.sh1s {
  font-size: 18px;
  font-weight: 500;
}

.sh1u {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.p1b {
  font-size: 16px;
  font-weight: 600;
}

.p1s {
  font-size: 16px;
  font-weight: 400;
}

.p1u {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.p2b {
  font-size: 14px;
  font-weight: 600;
}

.p2s {
  font-size: 14px;
  font-weight: 400;
}

.p2u {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.p3b {
  font-size: 12px;
  font-weight: 600;
}

.p3s {
  font-size: 12px;
  font-weight: 400;
}

.p3u {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.c3b {
  font-size: 10px;
  font-weight: 600;
}

.c3s {
  font-size: 10px;
  font-weight: 400;
}

.c3u {
  font-size: 10px;
  font-weight: 400;
  text-decoration: underline;
}

* {
  font-family: "Poppins";
}

.title-01 {
  font-family: "Poppins";
  color: #333333;
  font-size: 17px;
  font-weight: 600;
}

.title-3 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 700;
}

.title-2 {
  font-family: "Poppins";
  color: #333333;
  font-size: 21px;
  font-weight: 600;
}

.title-1 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 27px;
  font-weight: 700;
}

.parr-4 {
  font-size: 15px;
}

.parr-3 {
  color: rgba(53, 52, 52, 0.5);
}

.parr-2 {
  font-size: 14px;
  color: #414350;
  font-weight: bold;
}

.parr-1 {
  font-size: 11px;
  color: #388aed;
  font-weight: bold;
}

* {
  font-family: "Poppins";
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 990 !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  transition: opacity 1.5s;
}

.modal-container-1 {
  margin-top: 25vh;
  margin-left: 30vw;
  z-index: 991;
  width: 40vw;
  height: 5vh;
  opacity: 1;
}

.have-tooltip {
  position: relative;
  display: inline-block;
}

.have-tooltip:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip {
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip.cian {
  background-color: #ebf8ff;
  color: #2a2a2a;
}

.tooltip.cian::after {
  border-color: #ebf8ff transparent transparent transparent;
}

.tooltip.right {
  bottom: -120%;
  left: 164%;
  margin-left: -60px;
}

.up {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip.right::after {
  content: "";
  position: absolute;
  top: 34%;
  left: -4%;
  border-width: 5px;
  border-style: solid;
  width: 13px;
  transform: rotate(90deg);
}

.tooltip.up::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}

.h1b {
  font-size: 36px;
  font-weight: 700;
}

.h1s {
  font-size: 36px;
  font-weight: 600;
}

.h1m {
  font-size: 36px;
  font-weight: 500;
}

.h1bc {
  font-size: 24px;
  font-weight: 700;
}

.h1sc {
  font-size: 24px;
  font-weight: 600;
}

.h1mc {
  font-size: 24px;
  font-weight: 500;
}

.h2b {
  font-size: 30px;
  font-weight: 700;
}

.h2s {
  font-size: 30px;
  font-weight: 600;
}

.h2m {
  font-size: 30px;
  font-weight: 500;
}

.h2bc {
  font-size: 18px;
  font-weight: 700;
}

.h2sc {
  font-size: 18px;
  font-weight: 600;
}

.h2mc {
  font-size: 18px;
  font-weight: 500;
}

.h3b {
  font-size: 24px;
  font-weight: 700;
}

.h3s {
  font-size: 24px;
  font-weight: 600;
}

.h3m {
  font-size: 24px;
  font-weight: 500;
}

.h3bc {
  font-size: 16px;
  font-weight: 700;
}

.h3sc {
  font-size: 16px;
  font-weight: 600;
}

.h3mc {
  font-size: 16px;
  font-weight: 500;
}

.h4b {
  font-size: 18px;
  font-weight: 700;
}

.h4s {
  font-size: 18px;
  font-weight: 600;
}

.h4m {
  font-size: 18px;
  font-weight: 500;
}

.h4bc {
  font-size: 14px;
  font-weight: 700;
}

.h4sc {
  font-size: 14px;
  font-weight: 600;
}

.h4mc {
  font-size: 14px;
  font-weight: 500;
}

.h5b {
  font-size: 16px;
  font-weight: 700;
}

.h5sm {
  font-size: 16px;
  font-weight: 600;
}

.h5m {
  font-size: 16px;
  font-weight: 500;
}

.h5b {
  font-size: 12px;
  font-weight: 700;
}

.h5bc {
  font-size: 12px;
  font-weight: 600;
}

.h5mc {
  font-size: 12px;
  font-weight: 500;
}

.sh1b {
  font-size: 18px;
  font-weight: 700;
}

.sh1s {
  font-size: 18px;
  font-weight: 500;
}

.sh1u {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.p1b {
  font-size: 16px;
  font-weight: 600;
}

.p1s {
  font-size: 16px;
  font-weight: 400;
}

.p1u {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.p2b {
  font-size: 14px;
  font-weight: 600;
}

.p2s {
  font-size: 14px;
  font-weight: 400;
}

.p2u {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.p3b {
  font-size: 12px;
  font-weight: 600;
}

.p3s {
  font-size: 12px;
  font-weight: 400;
}

.p3u {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}

.c3b {
  font-size: 10px;
  font-weight: 600;
}

.c3s {
  font-size: 10px;
  font-weight: 400;
}

.c3u {
  font-size: 10px;
  font-weight: 400;
  text-decoration: underline;
}

* {
  font-family: "Poppins";
}

.title-01 {
  font-family: "Poppins";
  color: #333333;
  font-size: 17px;
  font-weight: 600;
}

.title-3 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 700;
}

.title-2 {
  font-family: "Poppins";
  color: #333333;
  font-size: 21px;
  font-weight: 600;
}

.title-1 {
  font-family: "Poppins";
  color: #3e3e3e;
  font-size: 27px;
  font-weight: 700;
}

.parr-4 {
  font-size: 15px;
}

.parr-3 {
  color: rgba(53, 52, 52, 0.5);
}

.parr-2 {
  font-size: 14px;
  color: #414350;
  font-weight: bold;
}

.parr-1 {
  font-size: 11px;
  color: #388aed;
  font-weight: bold;
}

.input-1 {
  border: 1px solid #e8e8ea;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  width: 100%;
  height: 38px;
}

.input-1::placeholder {
  font-weight: 400;
  color: #909090;
}

.input-1.error {
  border-color: rgba(255, 0, 0, 0.4);
}

.input-select {
  width: 100%;
  border: 1px solid #e8e8ea;
  border-radius: 10px;
  color: black;
}

.input-select > .ng-select-container {
  background-color: white !important;
}

.input-select > .ng-select-container > .ng-value-container > .ng-placeholder {
  font-weight: 400 !important;
  color: #909090 !important;
}

.input-select.error {
  border: 1px solid #e8e8ea !important;
}

.container-switch::ng-deep .mat-slide-toggle-thumb-container {
  top: -2px;
}

.container-switch::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #7fd77d;
  border-radius: 30px;
  height: 20px;
}

.container-switch::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white;
  width: 14px;
  height: 14px;
  transform: translate(18%, 38%);
}

.container-switch::ng-deep .mat-slide-toggle-content {
  font-size: 15px;
  font-family: "Poppins";
  color: #333333;
  font-weight: 400;
}

@font-face {
  font-family: "SFcompact";
  src: url("../../../assets/scss/fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: "SFcompactLight";
  src: url("../../../assets/scss/fonts/sf-compact-display-thin.otf");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Light.otf");
}
@font-face {
  font-family: "PoppinsThin";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Thin.otf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../../../assets/scss/fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: "WorkSansLight";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: "WorkSansSemiBold";
  src: url("../../../assets/scss/fonts/Work_Sans/WorkSans-SemiBold.ttf");
}
.poppinsR {
  font-family: "PoppinsRegular";
}

.poppinsT {
  font-family: "PoppinsThin";
}

.poppinsL {
  font-family: "PoppinsLight";
}

.poppinsB {
  font-family: "PoppinsBold";
}

.h1_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h1_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h1_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h1_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h1_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 5.8rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h2_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h2_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h2_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -1.5%;
}

.h2_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h2_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h3_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 2.8rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h3_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h3_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: -0.5%;
}

.h3_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h3_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h4_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h4_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h4_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h4_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h4_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.25%;
}

.h5_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h5_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h5_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h5_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h5_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 130%;
  letter-spacing: 0%;
}

.h6_300 {
  font-family: "PoppinsRegular";
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_300.secondary {
  color: #374357 !important;
}

.h6_400 {
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_400.secondary {
  color: #374357 !important;
}

.h6_500 {
  font-family: "PoppinsRegular";
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_500.secondary {
  color: #374357 !important;
}

.h6_600 {
  font-family: "PoppinsRegular";
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_600.secondary {
  color: #374357 !important;
}

.h6_700 {
  font-family: "PoppinsRegular";
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0;
  color: #000000;
}
.h6_700.secondary {
  color: #374357 !important;
}

p.subtitle-1, a.subtitle-1, span.subtitle-1 {
  font-family: "PoppinsRegular";
  font-size: 15px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.0015em;
  color: #6B778C;
}

.subtitle-2 {
  font-family: "PoppinsLight";
  font-size: 13px;
  font-weight: 500;
  line-height: 16.9px;
  letter-spacing: 0.001em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6B778C;
}

.body-1 {
  font-family: "PoppinsRegular";
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6B778C;
}

.body-2, .body-2-Pt {
  font-family: "PoppinsRegular";
  font-size: 13px;
  font-weight: 400;
  line-height: 16.9px;
  letter-spacing: 0.0025em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}

.body-2-Pt {
  font-family: "PoppinsThin";
}

.txt-c {
  text-align: center;
}

mat-sidenav-container.principal-menu {
  height: 100vh;
}
mat-sidenav-container.principal-menu .mat-drawer-inner-container {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
mat-sidenav-container.principal-menu mat-sidenav {
  height: 100%;
  width: 212px;
  background-color: #2E78D0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  overflow: hidden;
  transition: all 0.3s ease;
}
mat-sidenav-container.principal-menu mat-sidenav .menu-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  flex-direction: column;
  margin: 24px 0 20px 0;
  padding: 0 12px;
}
mat-sidenav-container.principal-menu mat-sidenav .menu-header .toggle-menu {
  border-radius: 50px;
  align-self: flex-end;
  padding: 0;
  min-width: auto;
  width: auto;
}
mat-sidenav-container.principal-menu mat-sidenav .menu-header .toggle-menu mat-icon {
  color: #FFFFFF;
}
mat-sidenav-container.principal-menu mat-sidenav .menu-header img.logo {
  height: 40px;
  width: auto;
  align-self: center;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list {
  flex: 1 1 auto;
  overflow-y: auto;
  margin-right: 6px;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item {
  height: 40px;
  width: calc(100% - 8px);
  position: relative;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  transition: all 0.2s ease;
  margin-bottom: 4px;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item .mat-ripple {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item .mat-list-item-content {
  padding: 0 10px;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item a.sidebar-link {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #FFFFFF;
  font-family: "PoppinsLight";
  font-size: 13px;
  font-weight: 400;
  line-height: 16.9px;
  letter-spacing: 0.0025em;
  text-align: left;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item a.sidebar-link mat-icon {
  display: flex;
  align-items: center;
  font-size: 20px;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item a.sidebar-link span {
  transition: opacity 0.3s ease, visibility 0.3s ease;
  white-space: nowrap;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item.active {
  background: #1E5EA4;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item.active::before {
  content: "";
  position: absolute;
  height: inherit;
  width: 4px;
  background-color: #95C1F6;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item:hover {
  background: #2367B3;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item:has(mat-nav-list) {
  height: auto !important;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item:has(mat-nav-list) > .mat-ripple {
  display: none;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item:has(mat-nav-list) .mat-list-item-content {
  padding: 0;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item:has(mat-nav-list) .submenu .submenu-name {
  padding-left: 10px;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item:has(mat-nav-list) .submenu .second-level .sidebar-link {
  padding-left: 48px;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item:has(mat-nav-list) .submenu-name {
  min-height: 40px;
}
mat-sidenav-container.principal-menu mat-sidenav .mat-nav-list.mat-nav-list mat-list-item.mat-list-item:has(mat-nav-list):hover {
  background: none;
}
mat-sidenav-container.principal-menu mat-sidenav.rail-mode {
  width: 68px;
  /* Ancho del rail */
}
mat-sidenav-container.principal-menu mat-sidenav.rail-mode .toggle-menu {
  align-self: center !important;
}
mat-sidenav-container.principal-menu mat-sidenav.rail-mode mat-nav-list.mat-nav-list {
  padding: 8px 6px 0px 8px;
  height: 70%;
}
mat-sidenav-container.principal-menu mat-sidenav.rail-mode mat-nav-list.mat-nav-list mat-list-item.mat-list-item {
  height: 40px;
  width: 44px;
}
mat-sidenav-container.principal-menu mat-sidenav.rail-mode mat-nav-list.mat-nav-list mat-list-item.mat-list-item span {
  visibility: hidden;
  opacity: 0;
}
mat-sidenav-container.principal-menu mat-sidenav.mat-sidenav > div > mat-nav-list.mat-nav-list {
  padding-bottom: 36px;
}
mat-sidenav-container.principal-menu mat-sidenav-content {
  transition: margin-left 0.3s ease;
}
mat-sidenav-container.principal-menu mat-sidenav-content.rail-mode {
  margin-left: 68px !important;
}
mat-sidenav-container.principal-menu mat-sidenav-content.drawer-mode {
  margin-left: 212px !important;
}

mat-nav-list.mat-nav-list::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
mat-nav-list.mat-nav-list::-webkit-scrollbar-track {
  background: transparent;
}
mat-nav-list.mat-nav-list::-webkit-scrollbar-thumb {
  background: #CAD4E0;
  border-radius: 1em;
}
mat-nav-list.mat-nav-list::-webkit-scrollbar-thumb:hover {
  background: #FFFFFF;
}

mat-drawer-content mat-sidenav-content {
  overflow-x: hidden;
}

nav.navbar.top-navbar {
  top: 0;
  position: sticky;
  z-index: 100000;
  background: #FFFFFF;
}

.main-navbar {
  height: 68px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;
  display: grid !important;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 0.7fr 1fr;
}
.main-navbar .nabvar-tools {
  display: grid;
  align-items: center;
  justify-content: flex-end;
  grid-auto-flow: column;
  gap: 16px;
}

.material-symbols-rounded.fill {
  font-variation-settings: "FILL" 1;
}

.mat-icon, .material-icons {
  font-family: "Material Symbols Rounded" !important;
}

/*******************************/
/*******************************/
@font-face {
  font-family: "SFcompact";
  src: url("./fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: "SFcompactLight";
  src: url("./fonts/sf-compact-display-thin.otf");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("./fonts/poppins/Poppins-Light.otf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("./fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("./fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: "MulishLight";
  src: url("./fonts/Mulish/Mulish-Light.ttf");
}
@font-face {
  font-family: "MulishRegular";
  src: url("./fonts/Mulish/Mulish-Regular.ttf");
}
@font-face {
  font-family: "MulishBold";
  src: url("./fonts/Mulish/Mulish-Bold.ttf");
}
@font-face {
  font-family: "WorkSansLight";
  src: url("./fonts/Work_Sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: "WorkSansSemiBold";
  src: url("./fonts/Work_Sans/WorkSans-SemiBold.ttf");
}
/*Theme Colors*/
/*Topbar Colors*/
/*Sidebar Colors*/
/*Boxed layout width*/
/*Shadow*/
/*transitions*/
/*Dark transparent bg*/
/*******************************/
/*******************************/
/**
* Table Of Content
*
*  1. Color system
*  2. Options
*  3. Body
*  4. Typography
*  5. Breadcrumbs
*  6. Cards
*  7. Dropdowns
*  8. Buttons
*  9. Typography
*  10. Progress bars
*  11. Tables
*  12. Forms
*  14. Component
*/
.custom__status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 20px;
  border-radius: 16px;
  font-family: "PoppinsRegular";
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.015em;
  text-align: center;
  color: #1E293B;
  margin: auto;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  flex-wrap: nowrap;
}
.custom__status.active, .custom__status.entered, .custom__status.created {
  background-color: #C3DCFA;
}
.custom__status.time-exceeded, .custom__status.completed-by-system, .custom__status.canceled, .custom__status.false-alarm, .custom__status.noCompleted {
  background-color: #FFCED0;
}
.custom__status.displacement, .custom__status.unresolve {
  background-color: #FDDDAD;
}
.custom__status.completed, .custom__status.left, .custom__status.resolve, .custom__status.attended {
  background-color: #A0E7D0;
}

/*******************************/
/*******************************/
@font-face {
  font-family: "SFcompact";
  src: url("./fonts/sf-compact-display-medium.otf");
}
@font-face {
  font-family: "SFcompactLight";
  src: url("./fonts/sf-compact-display-thin.otf");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("./fonts/poppins/Poppins-Light.otf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("./fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("./fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: "MulishLight";
  src: url("./fonts/Mulish/Mulish-Light.ttf");
}
@font-face {
  font-family: "MulishRegular";
  src: url("./fonts/Mulish/Mulish-Regular.ttf");
}
@font-face {
  font-family: "MulishBold";
  src: url("./fonts/Mulish/Mulish-Bold.ttf");
}
@font-face {
  font-family: "WorkSansLight";
  src: url("./fonts/Work_Sans/WorkSans-Light.ttf");
}
@font-face {
  font-family: "WorkSansSemiBold";
  src: url("./fonts/Work_Sans/WorkSans-SemiBold.ttf");
}
/*Theme Colors*/
/*Topbar Colors*/
/*Sidebar Colors*/
/*Boxed layout width*/
/*Shadow*/
/*transitions*/
/*Dark transparent bg*/
/*******************************/
/*******************************/
/**
* Table Of Content
*
*  1. Color system
*  2. Options
*  3. Body
*  4. Typography
*  5. Breadcrumbs
*  6. Cards
*  7. Dropdowns
*  8. Buttons
*  9. Typography
*  10. Progress bars
*  11. Tables
*  12. Forms
*  14. Component
*/
ngx-datatable.material.fluid-row-ngx-table {
  background-color: #f8f9fa;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-header {
  height: 56px;
  background: #FFFFFF;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-header .datatable-header-inner {
  height: inherit;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-header .datatable-header-cell {
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "PoppinsLight";
  font-size: 14px;
  font-weight: 600;
  line-height: 16.9px;
  letter-spacing: 0.001em;
  color: #1E293B;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-header .datatable-header-cell.padding-left-none {
  justify-content: start !important;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-header .datatable-header-cell.custom__wrap {
  text-wrap: wrap;
  text-align: left !important;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-header * {
  transition: width ease-in 400ms;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-row-wrapper {
  background: #FFFFFF;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-row-wrapper .datatable-body-row {
  background: #ffffff;
  border-bottom: 1px solid #B2AFB3;
  /* Aplica el borde a las filas individuales */
  transition: width ease-in 400ms;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-row-wrapper .datatable-body-row .datatable-body-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-row-wrapper .datatable-body-row .datatable-body-cell.left-align {
  justify-content: flex-start;
  text-align: start;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-row-wrapper .datatable-body-row .datatable-body-cell .buttons {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-row-wrapper .datatable-body-row .datatable-body-cell .buttons .btn-action {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  height: -webkit-fill-available;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-footer .custom-footer {
  width: inherit;
  height: inherit;
  display: flex;
  gap: 32px;
  padding: 0 24px;
  align-items: center;
  justify-content: flex-start;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-footer .custom-footer div.footer-item {
  display: flex;
  height: inherit;
  align-items: center;
  color: #726F75;
  font-family: "PoppinsRegular";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.0015em;
  line-height: 16.9px;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-footer .custom-footer div.footer-item:nth-child(1), ngx-datatable.material.fluid-row-ngx-table .datatable-footer .custom-footer div.footer-item:nth-child(1) strong {
  font-size: 15px;
  font-weight: 600;
  color: #1E293B;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-footer .custom-footer div.footer-item:nth-child(2) {
  line-height: 19.5px;
  gap: 8px;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-footer .custom-footer div.footer-item:nth-child(2) select {
  width: 53px;
  height: 32px;
  border: 1px solid #D7D5D8;
  border-radius: 8px;
  padding: 2px;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-footer .custom-footer div.footer-item:nth-child(3) .datatable-pager li a {
  color: #2E78D0 !important;
  font-family: "PoppinsRegular";
  font-size: 15px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.0015em;
  text-align: center;
}
ngx-datatable.material.fluid-row-ngx-table .datatable-row-wrapper {
  padding: 0px;
}
ngx-datatable.material.fluid-row-ngx-table *::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
ngx-datatable.material.fluid-row-ngx-table *::-webkit-scrollbar-track {
  background: transparent;
}
ngx-datatable.material.fluid-row-ngx-table *::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius: 1em;
}
ngx-datatable.material.fluid-row-ngx-table *::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.grey-rounded-scroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.grey-rounded-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.grey-rounded-scroll::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius: 1em;
}
.grey-rounded-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}