html, body {
  min-height: 100% !important;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeSpeed;
}

.fa-2x {
  font-size: 1.5em !important;
  min-width: 27px !important;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button {
  outline: none !important;
  box-shadow: none !important;
}

.full-width {
  width: 100%;
}

.text-small {
  font-size: 60% !important;
}

.topbar .navbar-collapse {
  padding: 0 !important;
}

.custom-dropdwon-menu {
  left: unset !important;
  right: 0 !important;
}

.select-lang {
  border-radius: 7px !important;

  &:hover {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}

.home-select-lang {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 100px;
}

.datatable-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;

  .datatable-header-cell {
    padding-top: 10px !important;
  }
}

.datatable-row-detail {
  background: none !important;
}

app-reservations-table,
app-income-table,
app-update-unit {
  .wrapper--custom-card_ind {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin: 0 !important;
  }

  .wrapper--custom-card {
    height: 620px !important;
    overflow: auto;
  }

  .data-table-pending--reservation {
    datatable-header {
      display: none !important;
    }

    datatable-row-wrapper {
      padding: 0 !important;

      .custom-row--data-table-pending {
        datatable-body-cell {
          padding: 0 !important;
        }

        .list-group-item {
          border: none !important;
        }
      }
    }

    datatable-footer {
      border: none !important;

      .page-count {
        display: none !important;
      }

      datatable-pager {
        margin: 0 !important;
      }
    }
  }
}

.custom-row--data-table {
  border-left: 4px solid #007bff !important;

  .cell-is-empty {
    .datatable-body-cell-label {
      width: 100%;
      text-align: center;

      &:after {
        content: '-'
      }
    }
  }

  .cell-data-center {
    .datatable-body-cell-label {
      width: 100%;
      text-align: center;
    }
  }

  .get-companion {
    .datatable-body-cell-label {
      &:after {
        content: 'personas';
        margin-left: 3px;
      }
    }

  }

  &.is-inactive {
    border-left: 4px solid #999ca1 !important;

    .datatable-body-cell-label {
      color: #999ca1;
    }
  }

  &.reservation-canceled {
    border-left: 4px solid #999ca1 !important;

    .datatable-body-cell-label {
      color: #999ca1;
    }
  }

  .datatable-body-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex !important;
    align-items: center !important;
  }
}

.datatable-header-cell {
  text-align: center !important;
}

.datatable-row-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

ngx-datatable.material {
  box-shadow: none !important;
}

.wrapper--custom-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.datatable-header {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.datatable-footer {
  border-top: none !important;
}

.title--custom-card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.form-group {
  .input-group, .input-group--custom {
    input, label.form-control {
      border-radius: 7px 0 0 7px;

      &.along {
        cursor: pointer;
        border-radius: 7px !important;
        padding-right: 30px !important;
      }
    }

    label.form-control {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button, label.btn {
      border-radius: 0 7px 7px 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 92.09px;
    }

    .btn-link {
      min-width: unset !important;
    }

    .input-group-append-date {
      i {
        font-size: 27px;
        color: rgba(0, 0, 0, 0.45);
        position: absolute;
        right: 1px;
        top: -2px;
        cursor: pointer;
      }
    }
  }

  label {
    margin-bottom: 0.1rem !important;
  }

  input {
    border-radius: 7px !important;

    &.with-prefix{
      padding-left: 50px;
    }
  }

  textarea {
    border-radius: 7px !important;
  }

  select {
    border-radius: 7px !important;
    /* height: calc(2.640625rem + 2px); */
  }

  .item-file-attach {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i.remove-file {
    cursor: pointer;
  }

  .phone-prefix {
    position: absolute;
    top: 29px;
    left: 23px;
  }
}

.search-group {
  position: relative;

  input {
    border-radius: 7px !important;
    padding-right: 70px;
}

  button {
    background: transparent;
    position: absolute;
    height: 100%;
    right: 0;

    &.btn-magnify {
      right: 35px;
    }

    &.btn-down {
      border-left: 1px solid #e9ecef;
      border-radius: 0 !important;
      right: 0;
    }
  }
}

.btn {
  border-radius: 7px !important;
}

.sebm-google-map-container {
  width: 100%;
  height: 100%;
  display: flex;
}

input.ng-invalid.ng-touched {
  border-color: rgba(255, 0, 0, 0.4);
}

textarea.ng-invalid.ng-touched {
  border-color: rgba(255, 0, 0, 0.4);
}

select.ng-invalid.ng-touched {
  border-color: rgba(255, 0, 0, 0.4);
}

ng-select.ng-invalid.ng-touched {
  .ng-select-container {
    border-color: rgba(255, 0, 0, 0.4) !important;
  }
}

.modal-content {
  background: transparent !important;
  border: none !important;
}

.custom--modal-form {
  background: #ffffff !important;
  border-radius: 15px;
  padding: 10px 30px;

  .modal-header {
    button {
      span {
        font-size: 2.2rem;
        color: #388bec;
        font-weight: 400;
      }
    }

    h4 {
      font-weight: 400;
    }
  }

  .modal-footer {
    border: none !important;
  }
}

app-reservations-table {
  .dashboard-cards {
    .list-group-item {
      border: none;
    }
  }
}

.dashboard-cards {
  h4 {
    font-weight: 400;
  }

  small {
    i {
      font-size: 20px;
    }
  }

  .content-body {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .content-body-messages {
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    .list-group-item {
      border: none;
    }
  }

  .list-group-item {
    border: none;
    border-left: 4px solid $primary;
  }

  .description {
    font-size: 80%;
    line-height: 1.3;
    margin: 0;
  }

  .badge {
    font-size: 70% !important;
    font-weight: bold;
    width: 20px;
  }
}

.profile-picture {
  height: 60px;
  border-radius: 50%;
}

.badge-status {
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &.active {
    background: $primary;
  }

  &.warning {
    background: $warning;
  }
}

.toast-container{
  z-index: 2147483649;
}

.double-bounce1, .double-bounce2 {
  background: $warning !important;
}

app-loading {
  display: none;

  &.isActive {
    z-index: 99999;
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

int-phone-prefix {
  &.invalid-phone {
    .input-group {
      border-color: rgba(255, 0, 0, 0.4);
    }
  }

  &.disabled {
    .form-control {
      background: #e9ecef;
      pointer-events: none !important;
    }
  }

  .input-group {
    border: 1px solid #e9ecef;
    border-radius: 7px;
    /* height: calc(2.640625rem + 2px); */

    .input-group-addon {
      .dropdown {
        height: 100%;
        border-right: 1px solid #e9ecef;

        .dropbtn {
          height: 100%;
          min-width: 50px !important;
          border-radius: 7px 0 0 7px !important;
          background: #e9ecef;
        }
      }

    }

    .form-control {
      border: none !important;
      height: 100%;
      border-radius: 0 7px 7px 0 !important;
    }
  }
}

.intl-tel-input {
  width: 100% !important;

  .country-list {
    .divider {
      display: none !important;
    }
  }

  .form-control {
    height: calc(2.640625rem + 2px) !important;
  }
}

.dropdown-toggle {
  &:after {
    display: none !important;
  }
}

.photo-user {
  width: 40px !important;
  height: 40px !important;
}

.remove-profile-img {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 20px;
  cursor: pointer;
  color: red;
  z-index: 1;
}

.content-profile-img {
  max-width: 180px !important;
}

app-update-unit, app-new-unit{
  .array-input-unit {
    input {
      padding-right: 28px;
    }

    i {
      cursor: pointer;
      position: absolute;
      right: 18px;
      top: 29px
    }
  }

  .circle-icon {
    border: 1px solid #2962ff;
    display: inline-block;
    width: 23px;
    text-align: center;
    border-radius: 50%;
  }

  .wrapper-proprietor-fields {
    position: relative;

    .content-proprietor-fields {
      &:not(:first-child) {
        margin-top: 0.5rem;
        padding-top: 1rem;
        border-top: 1px solid #e3e3e3;
      }
    }
  }
}

app-notices-table, app-new-tmp-building,
app-new-building, app-update-unit,
app-debts-table {
  .tab-content {
    border-top: 0;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #dee2e6;
    border-radius: 0 0 7px 7px;

    .card-body {
      max-height: 550px;
      overflow: auto;

      &.unset-overflow {
        max-height: unset !important;
        overflow: unset !important;
      }
    }
  }

  .nav-tabs {
    .nav-link {
      color: #a1aab2 !important;
      border-top-left-radius: 7px !important;
      border-top-right-radius: 7px !important;

      &.active {
        color: #202121 !important;
        font-weight: 700 !important;
      }
    }
  }

  .dashboard-cards {
    .content-body:first-child {
      border-top: none !important;
    }
  }

  .card-preview-notification {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .search-box {
    position: absolute;
    right: 10px;

    @media screen and (max-width: 1200px) {
      display: flex;
      align-content: center;
      justify-content: space-between;
      position: relative;
      padding-left: 10px;
      padding-bottom: 10px;
    }
  }
}

.content-notice {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-notice-file {
  label, a {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content--modal-form-confirmation {
  ngb-modal-backdrop {
    z-index: 9999 !important;
  }

  ngb-modal-window {
    z-index: 9999 !important;
  }
}

.custom-employees-select {
  button.dropdown-toggle {
    width: 8rem !important;

    &:active {
      color: #ffffff !important;
    }

    &:focus {
      color: #ffffff !important;
    }
  }

  .dropdown-menu{
    border-radius: 7px !important;
    min-width: 8rem !important;
  }
}

app-new-building {
  .custom-accordion-admin {
    .accordion {
      .card {
        margin: 0;

        .card-header {
          padding: 0 !important;
          background: transparent !important;
          border-bottom: 1px solid #e3e3e3;

          h5 {
            button {
              color: rgba(0, 0, 0, 0.7);
              padding: 10px 0;
              text-decoration: none !important;
              display: block;
              width: 100%;

              &.collapsed {
                color: rgba(0, 0, 0, 0.9);
                font-weight: bold;
              }
            }
          }
        }

        .card-body {
          padding: 10px 0;
          text-align: justify;
          background: rgba(0, 0, 0, 0.01);
          border-bottom: 1px solid #e3e3e3;
        }
      }
    }
  }
}

.unread-notice {
  background: rgba(0, 0, 0, 0.05);
}

.readed-notice-dashboard {
  border-left: 4px solid rgba(0, 0, 0, 0.05) !important;
}

.has-unread {
  width: 10px;
  height: 10px;
  background: #FFA640;
  position: absolute;
  top: 10px;
  border-radius: 50%;
  left: 30px;
}

.image-area {
  cursor: pointer;
  border-radius: 50%;
  width: 87.88px;
  height: 87.88px;

  &.selected {
    box-shadow: 0 0 7px 3px orange !important;
  }
}

.image-row-detail {
  border-radius: 50%;
  width: 45.94px;
  height: 45.94px;
}

.content-btn-image-area {
  &:hover {
    .remove-btn-image-area {
      display: block;
    }
  }

  .remove-btn-image-area {
    position: absolute;
    cursor: pointer;
    right: -1px;
    top: -6px;
    font-size: 20px;
    display: none;
  }
}

.ng-select {
  .ng-select-container {
    border-radius: 7px;
    height: 35px !important;
    /* min-height: calc(2.640625rem + 2px); */
    border: 1px solid #e9ecef !important;

    .ng-value-container {
      .ng-input {
        /* margin-top: 5px; */
      }

      .ng-placeholder {
        /* margin-top: 5px; */
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

label.font-size-90 {
  font-size: 90% !important;
}

.background-gray {
  background: rgba(0, 0, 0, 0.02);
  padding: 5px 10px;
}

.badge-custom-units {
  background: #e3e3e3;
  border-radius: 7px;
  padding: 3px 10px;

  i {
    cursor: pointer;
  }
}

.img-avatar {
  display: flex;
  align-items: center;
  img {
    width: 2rem;
    height: 2rem;
    border-radius:50px;
    -webkit-border-radius:50px;
    -moz-border-radius:50px;
    -ms-border-radius:50px;
    -o-border-radius:50px;
  }
}

.text-row {
  white-space: nowrap;
}

.msg_unread {
  width: 12px;
  height: 12px;
  background: #388ff0;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  font-size: 9px;
}

.reading-msg {
  background-color: rgba(56, 143, 240, 0.1);
}

.text-black {
  color: inherit;
}

app-messages-table {
  .content-message-card {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    //border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: $primary;
    .header-message {
      align-self: stretch;
      display: flex;
      padding: 0 20px;
      .header-title {
        color: $white;
        font-weight: 700;
      }
    }

    .body-message::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    .body-message::-webkit-scrollbar-thumb {
        background: rgba(204, 204, 204, 0.342);
        border-radius: 4px;
    }

    .body-message {
      width: 100%;
      align-self: stretch;
      flex-grow: 1;
      overflow: auto;
      background-color: $white;
      padding: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
        .content-conversation {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;


        .message-conversation {
          width: fit-content;
          border-radius: 7px;
          padding: 5px 10px;
          margin-bottom: 5px;
          max-width: 60%;
          background: rgba(56, 143, 240, 0.25);
        }

        &.message-mine {
          justify-content: flex-end !important;

          .message-conversation {
            background: white !important;
            border: 1px solid rgba(56, 143, 240, 0.25);
            margin-right: 5px
          }
        }
      }
    }

    .footer-message {
      align-self: stretch;
      padding: 20px;
      background-color: $white;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      textarea {
        height: 100%;
        resize: none !important;
        width: 100%;
        padding: 5px;
        border-radius: 7px;
      }
      textarea:focus {
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
      }
    }
  }

  .flex-column {
    display:  flex;
    flex-direction:  column;
  }

  .content-user-card {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    overflow-y: auto;
    overflow-x: hidden;
    .header-user {
      align-self: stretch;
      .content-header {
        display: flex;
        justify-content: space-between;
        margin: 15px 15px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .body-user {
      align-self: stretch;
      flex-grow: 1;
      max-height: fit-content;
      .user-msg {
        margin: 0px 20px;
        padding: 10px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .last-message-open {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content-photo-chat {
    height: 60px;
    margin: auto;
    background-size: cover !important;
  }
}

app-tmp-buildings-table {
  .datatable-header-cell {
    padding: 0 10px !important;
  }

  .datatable-body-cell {
    padding: 0 10px !important;
  }
}

app-messages-table {
  .btn-transparent {
    background: transparent;
  }
}

ng-select {
  .ng-input {
    input {
      text-transform: uppercase !important;
    }
  }
}

.building-sid--dashboard {
  border: 2px solid #388ff0;
  font-weight: 700;
  font-size: 25px;
  color: #028ee1;
  border-radius: 7px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

ngb-timepicker {
  input {
    border-radius: 7px !important;
  }
}

// Fix de actualizacion de la version de ngx-toastr
// https://stackoverflow.com/questions/53989445/ngx-toastr-toast-not-showing-in-angular-7
#toast-container > div {
  opacity: 1;
}
.toast {
  font-size: initial !important;
  border: initial !important;
  border-radius: 14px!important;
  backdrop-filter: blur(0) !important;
  width: auto!important;
  min-width: 300px!important;
  max-width: 600px!important;
  min-height: 80px!important;
  display: flex!important;
  align-items: center;
  font-family: $PPINS;
  font-weight: 700;
  font-size: 20px;
}
.toast-success {
  background-color: $greenAlert !important;
  background-image: url('../images/alert/check-toast.svg');
}
.toast-error {
  background-color: $redAlert !important;
}
.toast-info {
  background-color: $blueAlert !important;
}
.toast-warning {
  background-color: $yellowAlert !important;
}

.btn-group-toggle {
  .btn-info {
    background: transparent;
    border: 1px solid #388ff0;
    color: #388ff0;
    border-radius: 0 !important;
    min-width: 100px;
    cursor: pointer;

    &:first-child {
      border-radius: 7px 0 0 7px !important;
    }

    &:last-child {
      border-radius: 0 7px 7px 0 !important
    }

    &.active {
      background: #388ff0;
    }
  }
}

.transactionsPage {
  margin: 80px 0% 1% 0;
}

// STATUS
.status-Pendiente,.status-Pending,
.status-Waiting-Approve, .status-Waiting-Payment,
.status-Esperando-aprobación, .status-Esperando-el-pago, 
.waiting_approve, .waiting_payment, .not_completed{
  border-radius: 8px;
  padding: 5px 12px;
  font-family: $PPINS;
  font-weight: 600;
  font-size: 13px;
  line-height: 108.1%;
  color: #F29B3A;;
  background: #FEF1DE;
  display: block;
}

.status-Resuelta, .status-Solved,
.status-Aprobado, .status-Approved,
.status-Respondida, .status-Answered,
.status-completed, .approved{
  border-radius: 8px;
  padding: 5px 12px;
  font-family: $PPINS;
  font-weight: 600;
  font-size: 13px;
  line-height: 108.1%;
  color: #42CEA1;
  background: #D9F5EC;
  display: block;
}

.status-Vencido, .status-Expired,
.status-Rechazado, .status-Rejected, 
.status-expired-with-answer, .status-Vencido-con-respuesta,
.status-Cancelado, .status-Canceled,
.status-No-respondida, .status-Unanswered,
.status-unanswered, .canceled, 
.rejected{
  border-radius: 8px;
  padding: 5px 12px;
  font-family: $PPINS;
  font-weight: 600;
  font-size: 13px;
  line-height: 108.1%;
  color: #F35F68;
  background: #FDE7E8;
  display: block;
}

.status-En-curso, .status-In-progress{
  border-radius: 8px;
  padding: 5px 12px;
  font-family: $PPINS;
  font-weight: 600;
  font-size: 13px;
  line-height: 108.1%;
  color: #388AED;
  background: #EBF5FF;
}

*.justify-content-center{
  justify-content: center;
}

.hidden{
  opacity: 0;
}

:host ::ng-deep .mat-date-range-input-container{
  display: grid;
  align-items: center;
  grid-template-columns: 2fr .5fr 2fr;
  text-align: center;
  .mat-date-range-input-start-wrapper,
  .mat-date-range-input-end-wrapper{
    max-width: calc(100% - 4px)!important;
  }
}