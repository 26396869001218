mat-drawer-content mat-sidenav-content{
  overflow-x: hidden;
}

nav.navbar.top-navbar{
  top: 0;
  position: sticky;
  z-index: 100000;
  background: $neutral_white;
}

.main-navbar{
  height: 68px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;
  display: grid!important;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: .7fr 1fr;
  .nabvar-tools{
    display: grid;
    align-items: center;
    justify-content: flex-end;
    grid-auto-flow: column;
    gap: 16px;
  }
}